import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import SchemaOrg from './SchemaOrg'

const SEO: React.FC = function () {
    const {
        site: { siteMetadata: seo },
    } = useStaticQuery(graphql`
        query SEO {
            site {
                siteMetadata {
                    title
                    description
                    siteUrl
                    author
                    authorEmail
                    organization {
                        name
                        url
                    }
                }
            }
        }
    `)
    const title = seo.title
    const description = seo.description
    const image = seo.image
    const url = `${seo.siteUrl}`

    return (
        <React.Fragment>
            <Helmet
                titleTemplate={`%s | ${seo.title}`}
                htmlAttributes={{
                    lang: 'en',
                }}
            >
                {/* General tags */}
                <title>{title}</title>
                <meta name='description' content={description} />
                <meta name='image' content={image} />
                <link rel='canonical' href={url} />

                {/* OpenGraph tags */}
                <meta property='og:url' content={url} />
                <meta property='og:title' content={title} />
                <meta property='og:description' content={description} />
                <meta property='og:image' content={image} />

                {/* Twitter Card tags */}
                <meta name='twitter:card' content='summary_large_image' />
                <meta name='twitter:creator' content={seo.author} />
                <meta name='twitter:title' content={title} />
                <meta name='twitter:description' content={description} />
                <meta name='twitter:image' content={image} />
            </Helmet>
            <SchemaOrg
                url={url}
                title={title}
                image={image}
                description={description}
                siteUrl={seo.siteUrl}
                author={seo.author}
                organization={seo.organization}
                defaultTitle={seo.title}
            />
        </React.Fragment>
    )
}

export default SEO
