/** From
 * https://github.com/jlengstorf/gatsby-theme-jason-blog/blob/e6d25ca927afdc75c759e611d4ba6ba086452bb8/src/components/SEO/SEO.js
 */

import React from 'react'
import { Helmet } from 'react-helmet'
import { SiteSiteMetadataOrganization } from '../../graphql-types'

interface Props {
    author: string
    siteUrl: string
    defaultTitle: string
    description: string
    image: string
    title: string
    url: string
    organization: SiteSiteMetadataOrganization
}

export default React.memo<Props>(
    ({
        author,
        siteUrl,
        defaultTitle,
        description,
        image,
        title,
        url,
        organization,
    }) => {
        const schema = [
            {
                '@context': 'http://schema.org',
                '@type': 'WebSite',
                url,
                name: title,
                alternateName: defaultTitle,
                sourceOrganization: {
                    '@type': 'Organization',
                    url: organization.url,
                    name: organization.name,
                },
                creator:                       {
                    '@type': 'Person',
                    name: 'Calum Sieppert',
                },

            },
        ]

        return (
            <Helmet>
                {/* Schema.org tags */}
                <script type='application/ld+json'>
                    {JSON.stringify(schema)}
                </script>
            </Helmet>
        )
    }
)